import { Link } from "react-router-dom";

function Footer() {
  return (
    <>
      <footer>
        <div className="container-fluid">
          <div className="position-relative bg-primary footer rounded-4 d-flex">
            <div className="d-block z-2 w-100 px-lg-5 px-0">
              <div className="px-lg-8 px-4 pt-3">
                <div className="row mb-lg-5 mb-3">
                  <div className="col-lg-9 col-12 mb-6 mt-lg-4">
                    <h2 class="display-2 fw-bold text-white mt-4 mb-0 lh-1">
                      Join Our Platform for
                      <br />
                      Film{" "}
                      <u class="text-white">
                        <span class="text-white">Funding</span>
                      </u>
                    </h2>
                  </div>
                  <div className="col-lg-3 d-flex justify-content-center mt-lg-4 flex-column mb-5 text-right">
                    <Link
                      to="/login"
                      state={{
                        role: "investor",
                      }}
                      class="btn btn-white px-5 w-100 rounded-3"
                    >
                      <div className="my-2 fs-3">
                        GET STARTED <i className="bi bi-arrow-right ms-3"></i>
                      </div>
                    </Link>
                  </div>
                </div>

                <div className="row mb-6">
                  <div className="col-lg-3 col-6">
                    <div className="mb-4">
                      <h4 className="mb-3 text-white">Company</h4>
                      <ul className="list-unstyled nav nav-footer flex-column nav-x-0">
                        <li>
                          <Link to="/" className="nav-link text-white">
                            Home
                          </Link>
                        </li>
                        <li>
                          <Link to="/about" className="nav-link text-white">
                            About Us
                          </Link>
                        </li>
                        <li>
                          <Link to="/contact" className="nav-link text-white">
                            Contact Us
                          </Link>
                        </li>
                        <li>
                          <a href="#" className="nav-link text-white">
                            Career
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-6">
                    <div className="mb-4">
                      <h4 className="mb-3 text-white">Information</h4>
                      <ul className="list-unstyled nav nav-footer flex-column nav-x-0">
                        <li>
                          <Link to="/investor" className="nav-link text-white">
                            Investor
                          </Link>
                        </li>
                        <li>
                          <Link to="/filmmaker" className="nav-link text-white">
                            Filmmaker
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-6">
                    <div className="mb-4">
                      <h4 className="mb-3 text-white">Legal Documents</h4>
                      <ul className="list-unstyled nav nav-footer flex-column nav-x-0">
                        <li>
                          <Link
                            to="/terms-of-use"
                            className="nav-link text-white"
                          >
                            Terms of use
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/privacy-policy"
                            className="nav-link text-white"
                          >
                            Privacy Policy
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/cookie-policy"
                            className="nav-link text-white"
                          >
                            Cookies Policy
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/security-policy"
                            className="nav-link text-white"
                          >
                            Security Policy
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/payments-policy"
                            className="nav-link text-white"
                          >
                            Payments Policy
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/disclaimer"
                            className="nav-link text-white"
                          >
                            Disclaimer
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-12">
                    <div className="mb-4">
                      <h4 className="mb-3 text-white">Get in touch</h4>
                      <p className="text-white">
                        1101, 11th floor, Padmavati Heights, Vile Parle East,
                        Mumbai, Maharashtra - 400057
                      </p>
                      <p className="mb-1 text-white">
                        Email:{" "}
                        <a
                          href="mailto:contact@fundoramaa.com"
                          className="text-white"
                        >
                          contact@fundoramaa.com
                        </a>
                      </p>
                      <p className="text-white">
                        Phone:{" "}
                        <a href="tel:+918825315449" className="text-white">
                          +91 8825315449
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="position-relative bg-primary footer rounded-top-4 d-flex mt-1">
            <div className="d-block z-2 w-100 px-lg-5 px-0">
              <div className="px-lg-8 px-4 pt-3">
                <div className="pt-3 pb-5">
                  <div className="row">
                    <div className="col-lg-6 col-12 d-flex align-items-center justify-content-center">
                      <p className="text-white m-0">
                        Copyright &copy; 2024{" "}
                        <span className="fw-semibold">Fundoramaa</span>
                      </p>
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className="fs-4 d-flex align-items-center justify-content-center mt-lg-0 mt-2">
                        <a href="#" className="me-3">
                          <i className="bi bi-facebook text-white fs-3"></i>
                        </a>
                        <a href="#" className="me-3">
                          <i className="bi bi-twitter-x text-white fs-3"></i>
                        </a>
                        <a href="#" className="me-3">
                          <i className="bi bi-instagram text-white fs-3"></i>
                        </a>
                        <a href="#" className="me-3">
                          <i className="bi bi-linkedin text-white fs-3"></i>
                        </a>
                        <a href="#">
                          <i className="bi bi-youtube text-white fs-3"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
