import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";

function Register() {
  const [formData, setFormData] = useState({
    name: "",
    occupation: "",
    participatedIn: [],
    reasonsForInvoiceDiscounting: [],
    referralCode: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const userId = searchParams.get("userId");

  // Handle form input changes, including checkboxes and text fields
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: checked
          ? [...prevData[name], value] // Add the checkbox value if checked
          : prevData[name].filter((item) => item !== value), // Remove it if unchecked
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await axios.put(
        `https://ott-ep3g.onrender.com/api/v1/users/complete-profile/${userId}`,
        formData
      );

      if (response.data.token) {
        localStorage.setItem("authToken", response.data.token);
        navigate("/filmmaker/dashboard");
      }
    } catch (error) {
      console.error("Error completing profile:", error);
      setMessage("Error completing profile. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="login-bg vh-100 vw-100">
        <div className="container h-100 w-100 d-flex align-items-center">
          <div className="col-lg-5 col-md-7 col-12 offset-lg-7 offset-md-5">
            <div className="p-5 rounded-4 bg-white">
              <div className="m-5">
                <h2 className="lh-1 mb-2">
                  Create Account : Finish Your Registration Process
                </h2>
                <form onSubmit={handleSubmit}>
                  <div className="vh-60 overflow-scroll">
                    <div className="mt-4">
                      <label>Name</label>
                      <input
                        type="text"
                        name="name"
                        className="form-control mt-1"
                        placeholder="Your Name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="mt-3">
                      <label>What is your occupation?</label>
                      <div className="mt-1">
                        {[
                          "Producer",
                          "Director",
                          "Director of photography (D.O.P)",
                          "Writer",
                          "Line Producer",
                        ].map((option, id) => (
                          <div className="form-check" key={id}>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id={`occupation${id}`}
                              name="occupation"
                              value={option}
                              checked={formData.occupation.includes(option)}
                              onChange={handleChange}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`occupation${id}`}
                            >
                              {option}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="mt-4">
                      <label>Have you done any project before this?</label>
                      <div className="mt-1">
                        {[
                          "Film/Movie",
                          "Web Series",
                          "Web Film",
                          "Serial",
                          "Short Film",
                          "Documentary",
                          "All of the above",
                          "None of above",
                        ].map((option, id) => (
                          <div className="form-check" key={id}>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id={`participatedIn${id}`}
                              name="participatedIn"
                              value={option}
                              checked={formData.participatedIn.includes(option)}
                              onChange={handleChange}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`participatedIn${id}`}
                            >
                              {option}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="mt-4">
                      <label>Referral Code (Optional)</label>
                      <input
                        type="text"
                        className="form-control mt-1"
                        name="referralCode"
                        value={formData.referralCode}
                        onChange={handleChange}
                        placeholder="Referral Code"
                      />
                    </div>

                    <div className="mt-3">
                      {message && (
                        <div className="alert alert-info mt-3">{message}</div>
                      )}
                    </div>

                    <button
                      type="submit"
                      className="btn btn-primary btn-lg mt-4 w-100 rounded-3 fs-5"
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <div
                          className="spinner-border spinner-border-sm"
                          role="status"
                        ></div>
                      ) : (
                        "Complete Registration"
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Register;
